var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smb-client-wrapper"},[(_vm.isLoading)?_c('Loader'):[_c('SmbTitles',{attrs:{"is-show-title":"","is-show-sub-title":"","title":_vm.title,"sub-title":_vm.subTitle}}),_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"type-checker"},[_c('div',{staticClass:"type-checker__wrapper"},[_c('div',{staticClass:"type-checker__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeOfCreationContent),expression:"typeOfCreationContent"}],attrs:{"type":"radio","value":"post","id":"post"},domProps:{"checked":_vm._q(_vm.typeOfCreationContent,"post")},on:{"change":function($event){_vm.typeOfCreationContent="post"}}}),_c('label',{attrs:{"for":"post"}},[_c('span',{staticClass:"icon post"},[_c('img',{attrs:{"src":require("@/assets/img/icons/instagram_post.svg"),"alt":""}})]),_c('span',{staticClass:"text"},[_vm._v("Post")])])]),_c('div',{staticClass:"type-checker__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeOfCreationContent),expression:"typeOfCreationContent"}],attrs:{"type":"radio","value":"story","id":"story"},domProps:{"checked":_vm._q(_vm.typeOfCreationContent,"story")},on:{"change":function($event){_vm.typeOfCreationContent="story"}}}),_c('label',{attrs:{"for":"story"}},[_c('span',{staticClass:"icon story"},[_c('img',{attrs:{"src":require("@/assets/img/icons/instagram_story.svg"),"alt":""}})]),_c('span',{staticClass:"text"},[_vm._v("Story")]),_c('span',{staticClass:"label"},[_vm._v("New")])])])])]),_c('div',{staticClass:"buttons-wrapper",class:{
          column: !_vm.isShowMoreClicked && _vm.typeOfCreationContent === 'post',
        }},_vm._l((_vm.generateButtonsMenu.filter(
            (button, buttonIndex) => buttonIndex < _vm.generalBtnsLimit
          )),function(button){return _c('div',{key:button.id,class:{
            'buttons-row': !button.isShowForDemo || _vm.isCanSeeDemoFeatures,
          }},[(!button.isShowForDemo || _vm.isCanSeeDemoFeatures)?_c('SmbGeneralButton',{attrs:{"text":button.name,"subText":button.subText,"emitName":button.emitName,"isLoading":button && button.isLoading ? button.isLoading : false,"appendIcon":_vm.setAppendIconForGeneralBtn(button)},on:{"personalUpdate":_vm.personalUpdateClickHandler,"tips":_vm.tipsClickHandler,"vocabulary":_vm.vocabularyClickHandler,"questions&Answers":_vm.questionsAnswersHandler,"quote":_vm.quoteHandler,"listing":_vm.listingHandler,"testimonial":function($event){return _vm.checkIsNeedBrandingUpdatesBeforeTestimonial(button)}}}):_vm._e(),(button.subMenu && button.subMenu.length)?_vm._l((button.subMenu),function(subButton){return _c('SmbGeneralButton',{key:subButton.emitName + button.id,attrs:{"customClass":"small-text","text":subButton.name,"emitName":subButton.emitName,"is-loading":subButton.isLoading},on:{"surpriseMe":function($event){return _vm.checkIsNeedBrandingUpdatesBeforeSurpriseMe(subButton, button)}}})}):_vm._e()],2)}),0),(_vm.typeOfCreationContent === 'post')?_c('div',{staticClass:"show-more__container"},[_c('v-btn',{staticClass:"show-more__button",on:{"click":function($event){_vm.isShowMoreClicked = !_vm.isShowMoreClicked}}},[_vm._v(" "+_vm._s(_vm.isShowMoreClicked ? "Show less categories" : "Show more categories ")+" ")])],1):_vm._e(),(
          _vm.getRecommendationList.length && _vm.typeOfCreationContent === 'post'
        )?_c('div',{staticClass:"personalized-suggestions"},[_c('div',{staticClass:"personalized-suggestions__title"},[_vm._v(" Personalized suggestions ")]),_c('div',{staticClass:"personalized-suggestions__list"},_vm._l((_vm.getRecommendationList),function(recommendation){return _c('v-btn',{key:recommendation.id,staticClass:"personalized-suggestions__button",attrs:{"loading":recommendation.isLoading},on:{"click":function($event){return _vm.checkIsNeedBrandingUpdatesBeforeClickOnRecommendation(
                recommendation
              )}}},[_vm._v(" "+_vm._s(recommendation.title)+" ")])}),1),(!_vm.getIsDoneFlagForRecommendationList)?_c('SmbActionBtn',{attrs:{"isLoading":_vm.isLoadingGetMoreRecommendationButton,"text":"Load more","emitName":"loadMore","customClass":"load-more"},on:{"loadMore":function($event){return _vm.clickOnLoadMoreRecommendationsButton(4)}}}):_vm._e()],1):_vm._e(),(_vm.getCurrentCreationContentType === 'story')?_c('div',{staticClass:"bottom-text"},[_vm._v(" Want more types of stories? Send us your thoughts to "),_c('a',{attrs:{"href":`mailto:wishlist@social-wonder.com?subject=A%20request%20for%20a%20new%20feature`}},[_vm._v(" wishlist@social-wonder.com")])]):_vm._e()]),_c('div',{staticClass:"smb-client-wrapper__footer"},[_c('SmbActionBtn',{attrs:{"text":"Back"},on:{"click":function($event){return _vm.$router.push({ name: 'smb-client' })}}})],1),_c('SmbAddBrandingDetailsPopUp',{attrs:{"isShow":_vm.isShowBrandingPopUp,"actionType":_vm.isNeedToUpdateBranding,"emitName":_vm.emitNameForActionAfterPopup,"buttonsData":{
        button: _vm.dataObject,
        generalButton: _vm.lastUsedGeneralBtn,
        type: _vm.getCurrentCreationContentType,
      }},on:{"close":function($event){_vm.isShowBrandingPopUp = false},"surpriseMeHandler":function($event){return _vm.surpriseMeHandler(_vm.dataObject, _vm.lastUsedGeneralBtn)},"testimonialHandler":function($event){return _vm.testimonialHandler(_vm.dataObject)},"clickedOnRecommendation":function($event){return _vm.clickedOnRecommendation(_vm.dataObject)}}}),_c('SmbUpdatePlanModal',{attrs:{"is-show-update-plan-pop-up":_vm.isShowUpdatePlanPopUp,"plan-name":"Basic Package","subject-text":_vm.mailToSubject},on:{"close":_vm.clickOkOnTheChangePlanModal}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }