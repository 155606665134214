<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />

    <template v-else>
      <SmbTitles
        is-show-title
        is-show-sub-title
        :title="title"
        :sub-title="subTitle"
      />
      <div class="content-container">
        <div class="type-checker">
          <div class="type-checker__wrapper">
            <div class="type-checker__item">
              <input
                type="radio"
                v-model="typeOfCreationContent"
                value="post"
                id="post"
              />
              <label for="post">
                <span class="icon post">
                  <img src="@/assets/img/icons/instagram_post.svg" alt="" />
                </span>
                <span class="text">Post</span>
              </label>
            </div>
            <div class="type-checker__item">
              <input
                type="radio"
                v-model="typeOfCreationContent"
                value="story"
                id="story"
              />
              <label for="story">
                <span class="icon story">
                  <img src="@/assets/img/icons/instagram_story.svg" alt="" />
                </span>
                <span class="text">Story</span>
                <span class="label">New</span>
              </label>
            </div>
          </div>
        </div>

        <div
          class="buttons-wrapper"
          :class="{
            column: !isShowMoreClicked && typeOfCreationContent === 'post',
          }"
        >
          <div
            :class="{
              'buttons-row': !button.isShowForDemo || isCanSeeDemoFeatures,
            }"
            v-for="button in generateButtonsMenu.filter(
              (button, buttonIndex) => buttonIndex < generalBtnsLimit
            )"
            :key="button.id"
          >
            <SmbGeneralButton
              v-if="!button.isShowForDemo || isCanSeeDemoFeatures"
              :text="button.name"
              :subText="button.subText"
              :emitName="button.emitName"
              :isLoading="button && button.isLoading ? button.isLoading : false"
              :appendIcon="setAppendIconForGeneralBtn(button)"
              @personalUpdate="personalUpdateClickHandler"
              @tips="tipsClickHandler"
              @vocabulary="vocabularyClickHandler"
              @questions&Answers="questionsAnswersHandler"
              @quote="quoteHandler"
              @listing="listingHandler"
              @testimonial="checkIsNeedBrandingUpdatesBeforeTestimonial(button)"
            />
            <template v-if="button.subMenu && button.subMenu.length">
              <SmbGeneralButton
                v-for="subButton in button.subMenu"
                customClass="small-text"
                :key="subButton.emitName + button.id"
                :text="subButton.name"
                :emitName="subButton.emitName"
                :is-loading="subButton.isLoading"
                @surpriseMe="
                  checkIsNeedBrandingUpdatesBeforeSurpriseMe(subButton, button)
                "
              />
            </template>
          </div>
        </div>
        <div
          v-if="typeOfCreationContent === 'post'"
          class="show-more__container"
        >
          <v-btn
            class="show-more__button"
            @click="isShowMoreClicked = !isShowMoreClicked"
          >
            {{
              isShowMoreClicked
                ? "Show less categories"
                : "Show more categories "
            }}
          </v-btn>
        </div>
        <div
          class="personalized-suggestions"
          v-if="
            getRecommendationList.length && typeOfCreationContent === 'post'
          "
        >
          <div class="personalized-suggestions__title">
            Personalized suggestions
          </div>
          <div class="personalized-suggestions__list">
            <v-btn
              v-for="recommendation in getRecommendationList"
              :key="recommendation.id"
              :loading="recommendation.isLoading"
              class="personalized-suggestions__button"
              @click="
                checkIsNeedBrandingUpdatesBeforeClickOnRecommendation(
                  recommendation
                )
              "
            >
              {{ recommendation.title }}
            </v-btn>
          </div>

          <SmbActionBtn
            v-if="!getIsDoneFlagForRecommendationList"
            :isLoading="isLoadingGetMoreRecommendationButton"
            text="Load more"
            emitName="loadMore"
            customClass="load-more"
            @loadMore="clickOnLoadMoreRecommendationsButton(4)"
          />
        </div>
        <div
          v-if="getCurrentCreationContentType === 'story'"
          class="bottom-text"
        >
          Want more types of stories? Send us your thoughts to
          <a
            class=""
            :href="`mailto:wishlist@social-wonder.com?subject=A%20request%20for%20a%20new%20feature`"
          >
            wishlist@social-wonder.com</a
          >
        </div>
      </div>
      <div class="smb-client-wrapper__footer">
        <SmbActionBtn
          text="Back"
          @click="$router.push({ name: 'smb-client' })"
        />
      </div>
      <SmbAddBrandingDetailsPopUp
        :isShow="isShowBrandingPopUp"
        :actionType="isNeedToUpdateBranding"
        :emitName="emitNameForActionAfterPopup"
        :buttonsData="{
          button: dataObject,
          generalButton: lastUsedGeneralBtn,
          type: getCurrentCreationContentType,
        }"
        @close="isShowBrandingPopUp = false"
        @surpriseMeHandler="surpriseMeHandler(dataObject, lastUsedGeneralBtn)"
        @testimonialHandler="testimonialHandler(dataObject)"
        @clickedOnRecommendation="clickedOnRecommendation(dataObject)"
      />
      <SmbUpdatePlanModal
        :is-show-update-plan-pop-up="isShowUpdatePlanPopUp"
        plan-name="Basic Package"
        :subject-text="mailToSubject"
        @close="clickOkOnTheChangePlanModal"
      />
    </template>
  </div>
</template>

<script>
import LogRocket from "logrocket";
import { mapActions, mapGetters, mapMutations } from "vuex";

import {
  GENERATE_CONTENT_BUTTONS,
  SUBJECT_IDS,
  GENERATE_CONTENT_BUTTONS_IDS,
} from "@/constants/smb-client";
import H from "@/utils/helper";

import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbGeneralButton from "@/components/smb-client/SmbGeneralButton";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import Loader from "@/components/global/Loader";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import smbMethods from "@/mixins/smbMethods";
import SmbUpdatePlanModal from "@/components/smb-client/SmbUpdatePlanModal.vue";

export default {
  name: "SmbGenerateContent",
  data: () => ({
    menuButtons: GENERATE_CONTENT_BUTTONS,
    testimonialSubjectId: SUBJECT_IDS.TESTIMONIAL.GENERAL,
    generateButtonsIds: GENERATE_CONTENT_BUTTONS_IDS,
    isLoading: false,
    nameOfLastSurpriseMeAction: "",
    isShowBrandingPopUp: false,
    emitNameForActionAfterPopup: "",
    dataObject: {},
    lastUsedGeneralBtn: {},
    isShowMoreClicked: false,
    recommendations: [],
    isRecommendationListOver: false,
    isLoadingGetMoreRecommendationButton: false,
    isShowUpdatePlanPopUp: false,
    mailToSubject: "",
  }),
  components: {
    SmbUpdatePlanModal,
    SmbTitles,
    SmbGeneralButton,
    SmbActionBtn,
    Loader,
    SmbAddBrandingDetailsPopUp,
  },
  mixins: [smbMethods],
  computed: {
    ...mapGetters("smbClientSelection", [
      "getCurrentSmbCustomer",
      "isCanSeeDemoFeatures",
    ]),
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    ...mapGetters("recommendations", [
      "getRecommendationList",
      "getIsDoneFlagForRecommendationList",
    ]),
    isListingLink() {
      return !!this.isLink(this.listing);
    },
    popUpTextFirstPath() {
      return `The new ${this.nameOfLastSurpriseMeAction.toLowerCase()} ${
        this.getCurrentCreationContentType
      } is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
    title() {
      return this.getCurrentSmbCustomer.first_content_generated
        ? "Generate content"
        : "Let’s create your first content!";
    },
    subTitle() {
      return this.getCurrentSmbCustomer.first_content_generated
        ? "Select the type of content you would like to generate"
        : "Don’t worry, we won’t post it before you approve it!";
    },
    generalBtnsLimit() {
      if (this.typeOfCreationContent === "post") {
        return this.isShowMoreClicked ? this.menuButtons.length : 3;
      } else {
        return this.menuButtons.length;
      }
    },
    generateButtonsMenu() {
      if (this.typeOfCreationContent === "story") {
        return this.menuButtons
          .map((button) => {
            if (button.id === this.generateButtonsIds.CUSTOM_POST_STORY) {
              button.name = "Custom story";
              button.subText = "share your idea, get a tailored story";
              return button;
            }
            return button;
          })
          .filter((button) => button.id !== this.generateButtonsIds.TIPS);
      } else {
        return this.menuButtons.map((button) => {
          if (button.id === this.generateButtonsIds.CUSTOM_POST_STORY) {
            button.name = "Custom post";
            button.subText = "share your idea, get a tailored post";
            return button;
          }
          return button;
        });
      }
    },
    typeOfCreationContent: {
      get() {
        return this.getCurrentCreationContentType;
      },
      set(newType) {
        this.setCurrentCreationContentType(newType);

        this.checkCustomerPlan();
      },
    },
  },
  async created() {
    if (
      (this.$route.params && this.$route.params.isNeedLoadRecommendation) ||
      !this.getRecommendationList.length
    ) {
      this.isLoading = true;
      await this.fetchRecommendations(4);
    } else {
      if (this.getRecommendationList.length > 4) {
        this.removeRecommendationThatHaveIndexMoreThan(4);
      } else {
        if (!this.getIsDoneFlagForRecommendationList) {
          await this.fetchRecommendations(
            4 - this.getRecommendationList.length,
            true
          );
        }
      }
    }

    if (
      this.$route.params &&
      this.$route.params.emitName &&
      !H.isEmpty(this.$route.params.buttonsData)
    ) {
      let {
        button,
        generalButton,
        type = "post",
      } = this.$route.params.buttonsData;
      this.setCurrentCreationContentType(type);
      await this[this.$route.params.emitName](button, generalButton);
    }
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    ...mapActions("smbClientSelection", ["createPosts", "fetchSmbClientInfo"]),
    ...mapActions("recommendations", [
      "fetchRecommendationsList",
      "createPostFromRecommendation",
    ]),
    ...mapActions("smbPost", ["fetchAmountsPostsToReview"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    ...mapMutations("creationContentType", ["setCurrentCreationContentType"]),
    ...mapMutations("recommendations", [
      "removeRecommendationById",
      "setLoadingFlagForTheRecommendation",
      "removeRecommendationThatHaveIndexMoreThan",
    ]),
    personalUpdateClickHandler() {
      this.$router.push({ name: "personal-update" });
    },
    async checkIsNeedBrandingUpdatesBeforeSurpriseMe(buttonData, generalBtn) {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.emitNameForActionAfterPopup = "surpriseMeHandler";
        this.dataObject = buttonData;
        this.lastUsedGeneralBtn = generalBtn;
      } else {
        await this.surpriseMeHandler(buttonData, generalBtn);
      }
    },
    async checkIsNeedBrandingUpdatesBeforeClickOnRecommendation(button) {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.emitNameForActionAfterPopup = "clickedOnRecommendation";
        this.dataObject = button;
      } else {
        await this.clickedOnRecommendation(button);
      }
    },
    async clickedOnRecommendation(recommendationButton) {
      LogRocket.track("content_generation_recommendation");

      this.isShowBrandingPopUp = false;
      this.setLoadingForRecommendationsButton(recommendationButton, true);

      try {
        await this.createPostFromRecommendation({
          id: recommendationButton.id,
          task: "generate",
        });
        this.nameOfLastSurpriseMeAction = "";
        this.setPopupSettings({
          isShowPopup: true,
          popUpText: this.popUpText,
          isNeedToRedirect: false,
        });
      } catch (e) {
        throw e;
      } finally {
        this.removeRecommendationById(recommendationButton.id);
        await this.fetchAmountsPostsToReview({
          customerId: this.getCurrentSmbCustomer.id,
        });
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.setLoadingForRecommendationsButton(recommendationButton, false);

        if (
          !this.getRecommendationList.length &&
          !this.getIsDoneFlagForRecommendationList
        ) {
          await this.fetchRecommendations(4);
        }
      }
    },
    setLoadingForRecommendationsButton(recommendationButton, flag = false) {
      this.setLoadingFlagForTheRecommendation({ recommendationButton, flag });
    },
    isLink(text) {
      const expression =
        /https?:\/\/(www\.)?zillow\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      return !!text.match(regex);
    },
    tipsClickHandler() {
      this.$router.push({ name: "tips" });
    },
    vocabularyClickHandler() {
      this.$router.push({ name: "vocabulary" });
    },
    questionsAnswersHandler() {
      this.$router.push({ name: "questions-answers" });
    },
    quoteHandler() {
      this.$router.push({ name: "quote" });
    },
    async checkIsNeedBrandingUpdatesBeforeTestimonial(buttonData) {
      if (
        this.getCurrentSmbCustomer.zillow_page.trim() === "" ||
        !this.getCurrentSmbCustomer.zillow_page
      ) {
        await this.$router.push({
          name: "testimonial",
          params: { isFromGenerated: true },
        });
      } else {
        if (this.isNeedToUpdateBranding) {
          this.isShowBrandingPopUp = true;
          this.emitNameForActionAfterPopup = "testimonialHandler";
          this.dataObject = buttonData;
        } else {
          await this.testimonialHandler(buttonData);
          await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        }
      }
    },
    async testimonialHandler(buttonData) {
      this.isShowBrandingPopUp = false;
      if (
        this.getCurrentSmbCustomer.zillow_page.trim() === "" ||
        !this.getCurrentSmbCustomer.zillow_page
      ) {
        await this.$router.push({ name: "testimonial" });
      } else {
        LogRocket.track("content_generation_button");
        buttonData.isLoading = true;
        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          topicSubId: [this.testimonialSubjectId],
          bySmb: true,
          type: this.typeOfCreationContent,
        };

        try {
          await this.createPosts(params);
          this.nameOfLastSurpriseMeAction = buttonData.name;
          this.setPopupSettings({
            isShowPopup: true,
            popUpText: this.popUpText,
            isNeedToRedirect: false,
          });
          await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        } catch (e) {
          console.error(e);
          throw e;
        } finally {
          buttonData.isLoading = false;
        }
      }
    },
    async surpriseMeHandler(buttonData, generalBtnData) {
      this.isShowBrandingPopUp = false;
      if (buttonData.subjectId) {
        LogRocket.track("content_generation_button");
        buttonData.isLoading = true;
        try {
          const params = {
            customerId: this.getCurrentSmbCustomer.id,
            topicSubId: [buttonData.subjectId],
            bySmb: true,
            type: this.typeOfCreationContent,
          };

          await this.createPosts(params);
          this.nameOfLastSurpriseMeAction = generalBtnData.name;
          this.setPopupSettings({
            isShowPopup: true,
            popUpText: this.popUpText,
          });
          await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        } catch (e) {
          throw e;
        } finally {
          buttonData.isLoading = false;
        }
      }
    },
    listingHandler() {
      this.$router.push({ name: "listing" });
    },
    setAppendIconForGeneralBtn(btn) {
      if (btn.id === 7) {
        if (
          this.getCurrentSmbCustomer.zillow_page.trim() === "" ||
          !this.getCurrentSmbCustomer.zillow_page
        ) {
          return "mdi-chevron-right";
        } else {
          return "";
        }
      } else {
        return "mdi-chevron-right";
      }
    },
    async fetchRecommendations(
      recommendationsLimit = 0,
      isLoadMoreClicked = false
    ) {
      try {
        const params = {
          limit: recommendationsLimit,
          customerId: this.getCurrentSmbCustomer.id,
          isLoadMoreClicked,
        };

        await this.fetchRecommendationsList(params);
      } catch (e) {
        throw e;
      }
    },
    async clickOnLoadMoreRecommendationsButton() {
      this.isLoadingGetMoreRecommendationButton = true;
      await this.fetchRecommendations(4, true);
      this.isLoadingGetMoreRecommendationButton = false;
    },
    clickOkOnTheChangePlanModal() {
      this.setCurrentCreationContentType("post");
      this.isShowUpdatePlanPopUp = false;
    },
    checkCustomerPlan() {
      if (
        !this.isCanSmbCustomerGenerateStoriesInTestMode &&
        this.getCurrentSmbCustomer.plan === 1 &&
        this.getCurrentCreationContentType === "story"
      ) {
        this.mailToSubject = `Interested in learning more about upgrade`;
        this.isShowUpdatePlanPopUp = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f3f2f3;
  height: 100%;
  @media screen and (min-width: 760px) {
    ::v-deep {
      .smb-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #92c4ff;
        padding: 36px 20px 35px 20px;
        &__title {
          font-weight: 700;
          font-size: 36px;
          line-height: 140%;
        }
        &__sub-title {
          font-weight: 400;
          font-size: 30px;
          line-height: 140%;
          color: #fff;
          margin-top: 30px;
        }
      }
    }
  }
  .buttons-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    @media screen and (min-width: 760px) {
      padding: 35px 20px 20px 20px;
      column-count: 2;
      column-gap: 30px;
      max-width: 880px;
      &.column {
        display: flex;
        flex-direction: column;
        max-width: 410px;
      }
      ::v-deep {
        .smb-input {
          .v-input__slot {
            margin-top: 0;
            min-height: 70px;
            .v-input__prepend-inner,
            .v-input__append-inner {
              margin-top: 22px;
            }
          }
          .v-text-field__slot label {
            top: 24px;
          }
        }
      }
    }
  }
  .buttons-row {
    display: flex;
    align-items: center;
    ::v-deep {
      button {
        margin-right: 5px;
        box-shadow: none;
        margin-bottom: 10px;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (min-width: 760px) {
          height: 35px !important;
          font-size: 18px;
          margin-bottom: 17px;
        }
        &.small-text {
          border: 1px solid #1d1d1b !important;
          background: #ffffff !important;
          color: #1d1d1b !important;
          font-size: 11px !important;
          font-weight: 500 !important;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__footer {
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
  .show-more {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }
    &__button {
      height: 32px;
      background: #1d1d1b;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-transform: none;
      letter-spacing: inherit;
      color: #fff;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .personalized-suggestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 880px;
    margin: 12px auto 18px auto;
    padding: 0 20px 20px 20px;
    &__title {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #1d1d1b;
      margin-bottom: 13px;
    }
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__button {
      text-transform: none;
      white-space: inherit;
      text-indent: inherit;
      letter-spacing: inherit;
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      height: auto;
      line-height: 150%;
      color: #1d1d1b;
      background: #ffffff;
      border: 1px solid #000000;
      border-radius: 10px 10px 10px 0px;
      padding: 11px 15px;
      width: 100%;
      display: flex;
      margin-bottom: 9px;
      &:last-child {
        margin-bottom: 0;
      }
      ::v-deep {
        text-align: left;
        .v-btn__content {
          flex: auto;
          justify-content: flex-start;
        }
      }
    }
    ::v-deep {
      .load-more {
        margin-top: 15px;
        height: 32px !important;
        background: #1d1d1b;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-transform: none;
        letter-spacing: inherit;
        color: #fff;
      }
    }
  }
  .type-checker {
    max-width: 880px;
    margin: 20px auto 0 auto;
    @media screen and (max-width: 760px) {
      margin: 9px auto 0 auto;
    }
    &__wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      background: #ffffff;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
    }
    &__item {
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        display: inline-flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 8px;
        cursor: pointer;
        min-width: 130px;
        span {
          display: inline-flex;
        }
        .icon {
          height: 35px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .text {
          font-weight: 500;
          font-size: 22px;
          line-height: 150%;
          color: #999999;
        }
        .label {
          font-size: 12px;
          width: 100px;
          text-align: center;
          background: #70c5b0;
          position: absolute;
          display: inline-block;
          color: #fff;
          z-index: 10;
          transform: rotate(45deg);
          text-transform: uppercase;
          right: -34px;
          top: 9px;
        }
      }
      [type="radio"]:checked + label {
        background: #92c4ff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        .text {
          color: #000000;
        }
      }
    }
  }
  .bottom-text {
    text-align: center;
    margin: auto auto 0 auto;
    padding: 20px 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    color: #1f2325;
    a {
      color: #3771c8;
    }
  }
}
.update-plan-title {
  text-align: center;
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
  color: #1d1d1b;
  &__plan-name {
    color: #70c5b0;
  }
}
.update-plan-description {
  font-weight: 400;
  color: #1d1d1b;
  text-align: center;
  font-size: 17px;
  .support-link {
    color: #1d1d1b;
  }
}
.plan-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .btn-modal {
    width: 100%;
    max-width: 120px;
    .btn {
      background: #92c4ff;
      width: 100%;
    }
  }
}
</style>
